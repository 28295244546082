<template>
  <div class="home" v-loading="loading">
    <h3 class="common-title">图库列表</h3>
    <div class="clearfix pb20">
      <div class="fl pb5 pr5 pt5">
        <el-input type="text" v-model="galleryListFilter.keyword" placeholder="请输入图库名称" clearable
                  @keyup.enter="getGalleryList" style="min-width: 300px"></el-input>
      </div>
      <div class="fl pb5 pr5 pt5">
        <el-button
          type="primary"
          @click="getGalleryList">
          检索
        </el-button>
      </div>
      <!--      <div class="fr">-->
      <!--        <el-button type="primary" @click="newGallery">追加</el-button>-->
      <!--      </div>-->
    </div>
    <div class="home-left">
      <Empty v-if="galleryList.length === 0"></Empty>
      <el-table
        :data="galleryList"
        class="home-list"
        style="width: 100%"
        v-else>
        <el-table-column
          label="ID"
          prop="id"
          width="80">
        </el-table-column>
        <el-table-column
          prop="name"
          label="图库名称">
        </el-table-column>
        <el-table-column
          prop="size"
          label="图库大小">
        </el-table-column>
        <el-table-column
          prop="goods_count"
          label="入库商品数">
        </el-table-column>
        <el-table-column
          prop="goods_image_count"
          label="入库图片数">
        </el-table-column>
        <el-table-column
          prop="threshold"
          label="检索阈值">
        </el-table-column>
        <el-table-column label="操作" width="200px">
          <template #default="scope">
            <el-button
              type="warning"
              @click="chooseGallery(scope.row)"
              v-if="scope.row.is_default === 0">
              选择
            </el-button>
            <el-button
              type="danger"
              v-else>
              默认
            </el-button>
            <el-button
              type="primary"
              @click="editGalleryDialog(scope.row)">
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="clearfix pt20 pb20">
      <el-pagination
        class="fr"
        background
        layout="prev, pager, next, sizes, total"
        :total="galleryListFilter.totalSize"
        @current-change="getGalleryList"
        @size-change="getGalleryList(true)"
        v-model:current-page="galleryListFilter.page"
        v-model:page-size="galleryListFilter.size"
        :page-sizes="[10, 20, 50, 100]"
      />
    </div>
    <el-dialog v-model="galleryFormRef" :title="'编辑'" width="35%" destroy-on-close draggable>
      <div v-loading="loading">
        <el-form
          ref="galleryRuleFormRef"
          :model="galleryForm"
          :rules="galleryRules"
          label-width="120px"
          class="home-form"
        >

          <el-form-item label="图库名称" prop="name">
            <el-input v-model="galleryForm.name"></el-input>
          </el-form-item>
          <el-form-item label="检索阈值" prop="threshold">
            <el-input-number v-model="galleryForm.threshold" placeholder="40" :max="99" :min="10"></el-input-number>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="galleryFormRef = false">取消</el-button>
          <el-button type="primary" @click="doEditGallery">
            {{ '确定' }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {reactive, ref} from 'vue'
import {dayjs, ElMessage, ElMessageBox} from 'element-plus'
import {authApi} from '@/api'
import {onMounted} from "@vue/runtime-core";
import Empty from "@/components/Empty.vue";
import store from "@/store";
// @ is an alias to /src

export default {
  name: 'Home',
  components: {Empty},
  setup() {

    // gallery form
    const galleryList = ref([])
    const gallery = ref(null)
    const galleryFormRaw = reactive({
      id: null,
      name: null,
      threshold: 40,
    })
    const galleryForm = reactive(JSON.parse(JSON.stringify(galleryFormRaw)))
    const galleryFormRef = ref(false)

    const galleryRuleFormRef = ref(null)

    const galleryRules = reactive({
      name: [
        {required: true, message: '请输入图库名称', trigger: 'blur'},
      ],
      threshold: [
        {required: true, message: '请输入检索阈值', trigger: 'blur'},
      ],
    })

    const galleryListFilter = reactive({
      page: 1,
      size: 20,
      totalSize: 0,
      keyword: null,
    })
    const getGalleryList = (reset) => {
      if (reset === true) {
        galleryListFilter.page = 1
      }
      loading.value = true
      authApi.getGalleryList(galleryListFilter).then(res => {
        galleryList.value = res.data.list
        galleryListFilter.totalSize = res.data.total
      }).finally(() => {
        loading.value = false
      })
    }
    const newGallery = () => {
      Object.keys(galleryFormRaw).forEach(key => {
        galleryForm[key] = galleryFormRaw[key]
      })
      galleryFormRef.value = true
    }
    const createGallery = () => {
      ElMessageBox.confirm('确定要新增该图库吗?', '图库创建后无法删除和修改!', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'danger',
      }).then(() => {
        doCreateGallery()
      }).catch(() => {
      })
    }

    const doCreateGallery = () => {
      galleryRuleFormRef.value.validate((valid) => {
        if (valid) {
          loading.value = true
          authApi.createGallery(galleryForm).then(res => {
            console.log(res)
            ElMessage({
              message: '创建成功',
              type: 'success',
            })
            galleryFormRef.value = false
            getGalleryList()
          }).finally(() => {
            loading.value = false
          })
        }
      })
    }

    const loading = ref(false)

    const dateFormat = (row, column, cellValue) => {
      return dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss')
    }

    const chooseGallery = (row) => {
      ElMessageBox.confirm('确定要选择该图库吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'danger',
      }).then(() => {
        gallery.value = row
        authApi.chooseGallery(row.id).then(res => {
          ElMessage({
            message: '选择成功',
            type: 'success',
          })
          store.dispatch('setGallery')
          getGalleryList(true)
        })
      }).catch(() => {
      })
    }

    const editGalleryDialog = (row) => {
      Object.keys(galleryFormRaw).forEach(key => {
        galleryForm[key] = row[key]
      })
      galleryFormRef.value = true
    }

    const doEditGallery = () => {
      galleryRuleFormRef.value.validate((valid) => {
        if (valid) {
          loading.value = true
          authApi.editGallery(galleryForm.id, galleryForm).then(res => {
            console.log(res)
            ElMessage({
              message: '修改成功',
              type: 'success',
            })
            galleryFormRef.value = false
            store.dispatch('setGallery')
            getGalleryList()
          }).finally(() => {
            loading.value = false
          })
        }
      })
    }

    onMounted(() => {
      getGalleryList()
    })
    return {
      loading,
      //gallery
      galleryList,
      gallery,
      galleryForm,
      galleryFormRef,
      newGallery,
      createGallery,
      galleryRuleFormRef,
      galleryRules,
      getGalleryList,
      dateFormat,
      galleryListFilter,
      chooseGallery,
      editGalleryDialog,
      doEditGallery,
    }
  }
}
</script>
<style scoped>
</style>

<style>
.custom-upload .el-upload-list .el-upload-list__item .el-upload-list__item-actions .el-upload-list__item-preview {
  display: none !important; /* 隐藏预览按钮 */
}

.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-secondary);
  font-size: 23px;
}
</style>
